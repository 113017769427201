import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class CartForm extends ViewBasic{

    constructor(options) {
        super(options);

        this.timeoutClickDisabled = false;


        this.emailField = this.el.querySelector("#sender_email");
        this.nameField = this.el.querySelector("#sender_name");
        this.addressField = this.el.querySelector("#sender_address");
        this.cityField = this.el.querySelector("#sender_city");
        this.provinceField = this.el.querySelector("#sender_province");
        this.zipcodeField = this.el.querySelector("#sender_zipcode");
        this.conditionField = this.el.querySelector("#sender_conditions");

        this.submitBtn = this.el.querySelector("#submit_btn");
    }

    initialize() {
        bindAll(this, [ 'clickHandler','checkName','checkEmail','checkAddress','checkCity','checkProvince','checkZipcode', 'checkCondition']);
        this.addEvents();

        this.el.querySelectorAll('input').forEach(function(element){
            if(element.value){
                element.classList.add('has-value');
            }
        });
    }

    addEvents(){
        this.nameField.addEventListener('change', this.checkName);
        this.emailField.addEventListener('change', this.checkEmail);
        this.addressField.addEventListener('change', this.checkAddress);
        this.cityField.addEventListener('change', this.checkCity);
        this.provinceField.addEventListener('change', this.checkProvince);
        this.zipcodeField.addEventListener('change', this.checkZipcode);
        this.conditionField.addEventListener('change', this.checkCondition);

        this.submitBtn.addEventListener('click', this.clickHandler);
    };

    removeEvents(){
        this.submitBtn.removeEventListener('click', this.clickHandler);

        this.nameField.removeEventListener('change', this.checkName);
        this.emailField.removeEventListener('change', this.checkEmail);
        this.addressField.removeEventListener('change', this.checkAddress);
        this.cityField.removeEventListener('change', this.checkCity);
        this.provinceField.removeEventListener('change', this.checkProvince);
        this.zipcodeField.removeEventListener('change', this.checkZipcode);
        this.conditionField.removeEventListener('change', this.checkCondition);
    };

    /**
     * Press submit button
     * @param e
     */
    clickHandler(e){
        e.preventDefault();

        let scope = this;
        scope.el.className = "light-form";
        scope.el.classList.add('loading');

        //validate the form
        if(this.verifyFields()){
            //send form if valid.

            grecaptcha.ready(function() {
                //execute recaptcha
                grecaptcha.execute(window.SETTINGS.RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                    //save token for backend
                    scope.el.querySelector('[name="g-recaptcha-response"]').value = token;

                    scope.el.submit();
                });
            });
        }else{
            window.scrollTo({
                behavior: 'smooth',
                top:
                    this.el.querySelectorAll('.error')[0].getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top - 200,
            })
        }
    }

    /**
     * Verifies all the fields
     * @returns {boolean}
     */
    verifyFields(){

        if (this.checkEmail() && this.checkName() && this.checkAddress() && this.checkCity() && this.checkProvince() && this.checkZipcode() && this.checkCondition() ){
            return true;
        }

        this.el.classList.remove('loading');
        return false;
    }

    /**
     * Verifies name field
     * @returns {boolean}
     */
    checkName(){
        let valid = true;

        if(this.nameField.value === ''){
            this.nameField.classList.add('error');
            valid = false;
        }
        else{
            this.nameField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies address field
     * @returns {boolean}
     */
    checkAddress(){
        let valid = true;

        if(this.addressField.value === ''){
            this.addressField.classList.add('error');
            valid = false;
        }
        else{
            this.addressField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies city field
     * @returns {boolean}
     */
    checkCity(){
        let valid = true;

        if(this.cityField.value === ''){
            this.cityField.classList.add('error');
            valid = false;
        }
        else{
            this.cityField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies province field
     * @returns {boolean}
     */
    checkProvince(){
        let valid = true;

        if(this.provinceField.value === ''){
            this.provinceField.classList.add('error');
            valid = false;
        }
        else{
            this.provinceField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies email field
     * @returns {boolean}
     */
    checkEmail(){
        let valid = true;
        let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if(!this.emailField.value.match(emailRegex)){
            this.emailField.classList.add('error');
            valid = false;
        }
        else{
            this.emailField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies zipcode field
     * @returns {boolean}
     */
    checkZipcode(){
        let valid = true;
        let zipcodeRegex = /^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$/i;

        if(!this.zipcodeField.value.match(zipcodeRegex)){
            this.zipcodeField.classList.add('error');
            valid = false;
        }
        else{
            this.zipcodeField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies conditions field
     * @returns {boolean}
     */
    checkCondition(){
        let valid = true;

        if(!this.conditionField.checked){
            this.conditionField.classList.add('error');
            valid = false;
        }
        else{
            this.conditionField.classList.remove('error');
        }

        return valid;
    }

    terminate(){
        this.removeEvents();
    };
}

Me.views['CartForm'] = CartForm;
