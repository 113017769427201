import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'
import lightGallery from "lightgallery";
import lgVideo from "lightgallery/plugins/video/lg-video.umd";
import "lightgallery/scss/lightgallery.scss";

export class PagebuilderGallery extends ViewBasic{
    constructor(options) {
        super(options);
    }

    initialize() {
        bindAll(this, ['nextSlide','previousSlide', 'showPlayButton', 'hidePlayButton', 'mouseMove']);

        let scope = this;

        this.index = 1;
        this.slideCount = this.el.querySelectorAll('.images-ctn.large img').length;
        this.addEvents();

        lightGallery(this.el.querySelector('.lightgallery'), {
            licenseKey: process.env.LIGHTGALLERY_LICENSE,
            plugins: [lgVideo],
            nextHtml: scope.el.querySelector('.arrow-controls.next').outerHTML.toString().replace('button','span'),
            prevHtml: scope.el.querySelector('.arrow-controls.previous').outerHTML.toString().replace('button','span'),
            speed: 500,
            hideScrollbar: true, //Cette option empêche le scroll de la page quand la galerie est ouverte
        });
    }

    addEvents() {
        let scope = this;

        this.el.querySelector('.next').addEventListener('click', this.nextSlide);
        this.el.querySelector('.previous').addEventListener('click', this.previousSlide);

        this.el.addEventListener('mousemove', this.mouseMove);

        this.el.querySelectorAll('.video').forEach(function(element){
            element.addEventListener('mouseenter', scope.showPlayButton);
            element.addEventListener('mouseleave', scope.hidePlayButton);
        })
    }

    removeEvents() {
        let scope = this;

        this.el.querySelector('.next').removeEventListener('click', this.nextSlide);
        this.el.querySelector('.previous').removeEventListener('click', this.previousSlide);

        this.el.addEventListener('mousemove', this.mouseMove);

        this.el.querySelectorAll('.video').forEach(function(element){
            element.removeEventListener('mouseenter', scope.showPlayButton);
            element.removeEventListener('mouseleave', scope.hidePlayButton);
        })
    }
    mouseMove(e){
        var x = e.pageX - e.currentTarget.offsetLeft;
        var y = e.pageY - e.currentTarget.offsetTop;

        this.el.querySelector('.icon-play').style.top = y - (this.el.querySelector('.icon-play').offsetWidth /2);
        this.el.querySelector('.icon-play').style.left = x - (this.el.querySelector('.icon-play').offsetHeight /2) - this.el.querySelector('.centered-content').offsetLeft;
    }
    showPlayButton(){
        this.el.querySelector('.icon-play').classList.add('active');
    }
    hidePlayButton(){
        this.el.querySelector('.icon-play').classList.remove('active');
    }

    /**
     * Increase index on next button
     * @param e
     */
    nextSlide(e){
        this.index ++;
        this.updateSlideshowStatus();
    }

    /**
     * Decrease index on previous button
     * @param e
     */
    previousSlide(){
        this.index --;
        this.updateSlideshowStatus();
    }

    /**
     * Update Slideshow status (progress / total slide) and animate the new active slides in.
     * @param e
     */
    updateSlideshowStatus(){
        this.el.querySelector('.next').disabled = false;
        this.el.querySelector('.previous').disabled = false;

        if(this.index === this.slideCount){
            this.el.querySelector('.next').disabled = true;
        }else if(this.index === 1){
            this.el.querySelector('.previous').disabled = true;
        }

        //reset active state
        this.el.querySelectorAll('.images-ctn img.active').forEach(function(element){
            element.classList.remove('active');
        });
        //add active state to new images

        this.el.querySelectorAll('.images-ctn.large img')[this.index -1].classList.add('active');
        //sometimes the small image will not be available (end of slideshow)
        if(this.el.querySelectorAll('.images-ctn.small img')[this.index]){
            this.el.querySelectorAll('.images-ctn.small img')[this.index].classList.add('active');
        }

        //update index visible to the user
        this.el.querySelector('.index').innerHTML = this.index;
    }
}

Me.views['PagebuilderGallery'] = PagebuilderGallery;
