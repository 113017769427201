import { ViewBasic } from '@quatrecentquatre/manage-me';
import { CardFormService } from '../../services/card-form';
import { bindAll } from 'underscore'


export class GiftCardForm extends ViewBasic{

    //used by CardDisplay to receive the new card design
    static CARD_UPDATE_VISUAL = 'card.update_visual';



    constructor(options) {
        super(options);

        this.timeoutClickDisabled = false;

        this.recipientFields = this.el.querySelectorAll('[name="fields[form_recipient_type]"]');
        this.customAmount = this.el.querySelector("#card-custom-amount");
        this.nameField = this.el.querySelector("#recipient");
        this.senderNameField = this.el.querySelector("#sender");
        this.emailField = this.el.querySelector("#recipient-email");
        this.messageField = this.el.querySelector("#message");
        this.submitBtn = this.el.querySelector("#submit_btn");
    }

    initialize() {
        this.characterCounterElement = this.el.querySelector(".count");
        this.typedCharElement = document.querySelector("#current");
        this.maxChar = 200;

        bindAll(this, ['updateRecipientFields', 'amountSelected', 'updateTextareaCount', 'dispatchDesignSrcChange', 'clickHandler','checkCustomAmount','checkName','checkEmail','checkSenderName']);
        this.addEvents();
        this.updateRecipientFields();

        this.el.querySelectorAll('input').forEach(function(element){
            if(element.value){
                element.classList.add('has-value');
            }
        });

        // FormService for fetch.
         this.service = new CardFormService();

    }

    // Check for selected card design and dispatch a click, so it is displayed in the aside
    afterAllViewInitialize() {
        let scope = this;
        this.el.querySelectorAll('.design').forEach(function(element){
            if(scope.el.querySelector('input#' + element.htmlFor + ':checked')){
                element.dispatchEvent(new Event('click'));
            }
        });
    }

    addEvents(){
        let scope = this;

        //changing card design
        this.el.querySelectorAll('.design').forEach(function(element, index){
            element.addEventListener('click', scope.dispatchDesignSrcChange);
        });

        this.el.querySelectorAll('.amount input').forEach(function(element, index){
            element.addEventListener('click', scope.amountSelected);
        });


        //update character count
        this.messageField.addEventListener("input", this.updateTextareaCount);

        //show and hide extra fields for recipient
        this.recipientFields.forEach( function(element){
            element.addEventListener("change", scope.updateRecipientFields);
        });

        //validations for input fields
        this.customAmount.addEventListener('change', this.checkCustomAmount);

        this.nameField.addEventListener('change', this.checkName);
        this.senderNameField.addEventListener('change', this.checkSenderName);
        this.emailField.addEventListener('change', this.checkEmail);

        this.submitBtn.addEventListener('click', this.clickHandler);
    };

    removeEvents(){
        this.submitBtn.removeEventListener('click', this.clickHandler);
        this.customAmount.removeEventListener('change', this.checkCustomAmount);

        this.nameField.removeEventListener('change', this.checkName);
        this.senderNameField.removeEventListener('change', this.checkSenderName);
        this.emailField.removeEventListener('change', this.checkEmail);
    };

    updateRecipientFields(){
        if(this.el.querySelector('#card-recipient-type-1').checked){
            //show recipient fields
            this.el.querySelector('.recipient-fields').classList.remove('hide');
            this.el.querySelectorAll('.recipient-fields input').forEach(function(element){
                element.required = true;
            });
            //show custom message fields
            this.el.querySelector('.message-wrapper').classList.remove('hide');
            this.el.querySelector('#message').required = true;
            this.el.querySelector('#sender').required = true;


        }else{
            //hide recipient fields
            this.el.querySelector('.recipient-fields').classList.add('hide');
            this.el.querySelectorAll('.recipient-fields input').forEach(function(element){
                element.required = false;
            });

            //hide custom message fields
            this.el.querySelector('.message-wrapper').classList.add('hide');
            this.el.querySelector('#message').required = false;
            this.el.querySelector('#sender').required = false;
        }
    }

    dispatchDesignSrcChange(e){
        let scope = this;
        if(this.timeoutClickDisabled){
            return;
        }

        this.el.querySelectorAll('.design').forEach(function(element){
            scope.timeoutClickDisabled = true;

            scope.el.querySelectorAll('.design').forEach(function(element){
                element.classList.add('disabled');
            });
        });

        window.dispatchEvent(new CustomEvent(GiftCardForm.CARD_UPDATE_VISUAL,
            {
                detail: {
                    src: e.target.querySelector('img').src,
                }
            }
        ));

        setTimeout(function(){
            scope.timeoutClickDisabled = false;

            scope.el.querySelectorAll('.design').forEach(function(element){
                element.classList.remove('disabled');
            });
        }, 800);
    }

    /*
    //code copied from here: https://wiryawanadipa.com/blog/live-character-counter-on-text-area-element-using-vanilla-javascript/
    //class for warning not used, left just in case we need to indicate
    */

    updateTextareaCount(e){
        this.typedChar = this.messageField.value.length;
        if (this.typedChar > this.maxChar) {
            return false;
        }
        this.typedCharElement.textContent = this.typedChar;
        if (this.typedChar > this.maxChar - 1) {
            this.characterCounterElement.classList = "count text-danger";
        } else if (this.typedChar < this.maxChar && this.typedChar > this.maxChar * (90 / 100)) {
            this.characterCounterElement.classList = "count text-warning";
        } else if (this.typedChar < this.maxChar - ((this.maxChar * (10 / 100)) - 1)) {
            this.characterCounterElement.classList = "count text-normal";
        }
    }

    /**
     * Press submit button
     * @param e
     */
    clickHandler(e){
        e.preventDefault();

        let scope = this;
        scope.el.className = "light-form";
        scope.el.classList.add('loading');

        //validate the form
        if(this.verifyFields()){
            //send form if valid.

            grecaptcha.ready(function() {
                //execute recaptcha
                grecaptcha.execute(window.SETTINGS.RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                    //save token for backend
                    scope.el.querySelector('[name="g-recaptcha-response"]').value = token;

                    //submit form with fetch api.
                    scope.service.sendCardForm(scope.el.getAttribute('action'),new FormData(scope.el))
                        .then(response => {
                            if(response.errors){
                                // form submit successfully, but errors returned
                                scope.el.querySelector('.error').innerHTML = response.errors[0];
                                scope.el.classList.add('error');
                            }else{
                                //success
                                window.location = response.redirectUrl;
                            }
                        })
                        .catch(error => {
                            //PHP error
                            scope.el.classList.add('error-php');
                        });
                });
            });
        }else{
            window.scrollTo({
                behavior: 'smooth',
                top:
                    this.el.querySelectorAll('.error')[0].getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top - 200,
            })
        }
    }

    /**
     * Verifies all the fields
     * @returns {boolean}
     */
    verifyFields(){
        if(this.el.querySelector('#card-recipient-type-1').checked){
            if (this.checkCustomAmount() && this.checkName() && this.checkEmail() && this.checkSenderName()){
                return true;
            }
        }else{
            if (this.checkCustomAmount()){
                return true;
            }
        }

        this.el.classList.remove('loading');
        return false;
    }

    /*
    * Remove custom amount when we select one of the pre-determined amount
     */
    amountSelected (){
        this.customAmount.value = '';
        this.customAmount.classList.remove('has-value');
    }

    checkCustomAmount (){
        let valid = true;

        if(this.customAmount.value != '' && !this.el.classList.contains('success')){
            this.el.querySelectorAll('.amount input:checked').forEach((element) => {
                element.checked = false;
            });

            if(this.customAmount.value < 25 || this.customAmount.value > 500){
                this.customAmount.classList.add('error');
                valid = false;
            }
        }
        else{
            this.customAmount.classList.remove('error');
        }
        return valid;
    }

    /**
     * Verifies firstname field
     * @returns {boolean}
     */
    checkName(){
        let valid = true;

        if(this.nameField.value === ''){
            this.nameField.classList.add('error');
            valid = false;
        }
        else{
            this.nameField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies firstname field
     * @returns {boolean}
     */
    checkSenderName(){
        let valid = true;

        if(this.senderNameField.value === ''){
            this.senderNameField.classList.add('error');
            valid = false;
        }
        else{
            this.senderNameField.classList.remove('error');
        }

        return valid;
    }

    /**
     * Verifies email field
     * @returns {boolean}
     */
    checkEmail(){
        let valid = true;
        let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if(!this.emailField.value.match(emailRegex)){
            this.emailField.classList.add('error');
            valid = false;
        }
        else{
            this.emailField.classList.remove('error');
        }

        return valid;
    }

    terminate(){
        this.removeEvents();
    };
}

Me.views['GiftCardForm'] = GiftCardForm;
